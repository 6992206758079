<template>
  <div class="wrapper flex" v-if="!$store.state.cookies">
    <div class="card">
      <div class="flex">
        <img src="../assets/img/cookie.png" alt="Cookie">
        Diese Website verwendet Cookies. Wenn Du weitersurfst, stimmst Du der Cookie-Nutzung zu.
        <button class="cookiebtn_adapt" @click="options">Anpassen</button>
        <button class="cookiebtn_okay" @click="accept">Ok</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7em;
  color: #002060;
}

.wrapper {
  position: fixed;
  bottom: 1em;
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  
  @media screen and (max-width: 992px) {
      bottom: 0em;
    }
}

img {
  height: 2em;
}

.card {
  background: white;
  text-align: center;
  padding: 1em;
  font-size: 0.9em;

  border-radius: 4px;
  box-shadow: 0 10px 70px rgba(0,0,0,.06);

  .flex {
    flex-wrap: wrap;
  }
  
  @media screen and (max-width: 845px) {
      border-radius: 0px;
      padding: 2em;
      box-shadow: 0px -10px 45px 0 rgb(0 0 0 / 10%);
    }
}

.cookiebtn_adapt{
  font-size: 0.9em;
  padding: 0.25em 1em;
  border: 3px solid #f5f7ff;
  background: #f5f7ff;
  color: #002060;
}

.cookiebtn_adapt:hover{
  color: #fff;
  background: #4285f4;
  border: 3px solid #4285f4;
}

.cookiebtn_okay{
  font-size: 0.9em;
  padding: 0.25em 1em;
  border: 3px solid #4285f4;
  background: #4285f4;
  color: #fff;
}

.cookiebtn_okay:hover{
  color: #002060;
  background: #f5f7ff;
  border: 3px solid #f5f7ff;
}

</style>

<script>
export default {
  name: "Cookies",
  methods: {
    accept(){
      this.$store.commit("acceptCookies", {ga: true, lr: true});
    },
    options(){
      this.$emit("showCookieSettings");
    }
  }
}
</script>
