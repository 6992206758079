import { loadStripe } from '@stripe/stripe-js';
import config from '@/config';

const stripePublicKey = config.stripePublicKey;
export default {
  install(Vue) {
    Vue.prototype.$loadStripe = () => {
      return loadStripe(stripePublicKey);
    };
  },
};
