<template>
    <footer>
      <div class="flex">
        <h2 class="text">Support 💙</h2>
        <div class="subtitle_supporters">
            <p>Gefördert durch Mittel der Europäischen Union und dem Land Berlin</p>
        </div>
        <section class="section-1">
         <div class="logos-wrapper">
            <div>
              <img
                src="../assets/img/Be_Berlin_Logo.jpg"
                alt="berlin-logo"
               />
            </div>
            <div>
              <img 
                src="../assets/img/BHT_Logo.png" 
                alt="bht-logo" />
            </div>
         </div>

         <div class="logos-wrapper">
            <div>
                <img 
                src="../assets/img/esf-berlin.jpg" 
                alt="esf-logo" />
            </div>
            <div>
              <img
                src="../assets/img/EU_ESI-Fonds_rechts.jpg"
                alt="eu-esi-logo"
              />
            </div>
         </div>
         
         <div class="logos-wrapper">
            <div>
              <img
                src="../assets/img/BSS_Logo.png"
                alt="BSS_Logo"
               />
            </div>
         </div>
        </section>
      </div>
    </footer>
  </template>
  
  <style lang="scss" scoped>
  @import "../assets/css/_variables";
  
  .subtitle_supporters {
    max-width:800px;
    margin: 0px auto;
  }
  
  .subtitle_supporters p{
    color: #002060;
    font-size: 21px;
    text-align: left;
    margin-bottom: 100px;
    
    @media screen and (max-width: 640px) {
        font-size: 16px;
      }
  }
  
  .section-1 {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 80px;
  }
  .logos-wrapper {
    display: flex;
    flex-grow: 0.15;
    justify-content: space-between;
    align-items: center;
  }
  .flex {
    background: #fff;
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 640px) {
      padding: 10px 2em;
    }
    color: $darkBlue;
    .text {
      margin: 100px 0px 0px 0px;
      font-size: 3em;
      display: flex;
      flex-direction: column;
    }
    .text {
      text-align: center;
      @media screen and (max-width: 640px) {
        max-width: 100%;
      }
    }

    img {
    width: 10em;
    
    @media screen and (max-width: 640px) {
      margin: 10px 0px;
    }
    
    }
    @media screen and (max-width: 640px) {
      .text {
        font-size: 1.75em;
      }
    }
  }
    @media screen and (max-width: 992px) {
      .esf-logo {
        margin-top: 0.6em;
      }
    }
    @media screen and (max-width: 992px) {
       .bth-logo {
        margin-top: 0.6em;
      }
    }
  @media screen and (max-width: 992px) {
    .flex ul {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      margin-bottom: 100px;
    }
    .flex li {
      height: 20vh;
    }
    @media screen and (max-width: 992px) {
        .section-1 {
            gap: 40px;
            flex-direction: column;
            align-items: center;
        }
        .logos-wrapper {
            display: flex;
            gap: 40px;
        }
    }
    @media screen and (max-width: 576px) {
        .section-1 {
            gap: 5px;
        }
        .logos-wrapper {
            flex-direction: column;
            gap: 5px;
        }
    }
  }
  </style>
<script>
  export default {
    name: "PreFooter",
  };
</script>
