<template>
  <section class="section-1">
    <div class="item">
      <div class="flex">
        <div class="header">
          <h3>{{ item.title }}</h3>
          <h4>
            <span v-for="(topic, index) in item.case_topics" :key="topic.id"
              >{{ topic.title
              }}{{ index + 1 != item.case_topics.length ? ',' : '' }}
            </span>
          </h4>
        </div>
        <AddToBasketBtn class="basketBtn" :item="item" />
      </div>
    </div>

    <div class="description">
      <div v-html="item.description"></div>
      <h4>
        <span class="books" v-for="book in item.books" :key="book.id"
          >{{ book.title }}
        </span>
      </h4>
    </div>
    <div class="description">
      <div class="solvable" :class="{ green: item.solvable }">
        {{
          item.solvable
            ? 'Fallfrage, Musterlösung und Quiz erscheinen auf der heruntergeladenen PDF.'
            : 'Fallfrage, Musterlösung und Quiz erscheinen nicht auf der heruntergeladenen PDF'
        }}
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import '../assets/css/_variables';
.section-1 {
  padding: 4em 8em;
  border-radius: 7px;
  margin: 0px auto;
  max-width: 800px;

  @media screen and (max-width: 992px) {
    padding: 4em 4em;
  }
  
  @media screen and (max-width: 640px) {
    padding: 1em;
  }
}

section {
  padding: 1em;
  color: $darkBlue;
  letter-spacing: 0.007em;
  line-height: 25px;

  div {
    position: relative;
  }
}

.item {
  position: relative;
  max-width: 730px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  color: $darkBlue;
  margin-left: 35px;
  margin-bottom: 2em;
  
  @media screen and (max-width: 640px) {
    margin: 0 15px 35px 15px;
    }
  
      
  .header {
    position: relative;
  }
  h3 {
    font-size: 2.5em;
    margin-bottom: 10px;
    user-select: none;
    line-height: 1.5;
    
    @media screen and (max-width: 640px) {
    font-size: 1.7em;
    }
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }
  h2 {
    color: $blue;
    position: relative;
    max-width: 800px;
    flex-basis: auto;
    flex-grow: 1;
    width: 100%;

    margin-bottom: 1em;
  }

  p {
    margin: 0px;
    width: 90%;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.description {
  max-width: 800px;
  background: #fff;
  padding: 2em;
  box-shadow: 0 10px 70px rgba(0,0,0,.04);
  border-radius: 7px;
  margin-bottom: 60px;
  a {
    text-decoration: none !important;
    color: $blue;
  }
  div {
    white-space: pre-line;
  }

  @media screen and (max-width: 640px) {
    padding: 1em;
  }
  .books {
    margin-right: 1em;
    padding: 0.5rem;
    border-radius: 3px;
    background: $background;
  }
}

.description h4 {
  line-height: 50px;
}

.solvable {
  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    border-radius: 50%;
    background-color: rgb(220, 31, 31);
  }
  &.green {
    &::before {
      background-color: rgb(26, 183, 21);
    }
  }
}

.basketBtn {
  position: absolute;
  right: 45px;
  z-index: 2;

  @media screen and (max-width: 640px) {
    right: 17px;
    top: 40px;
  }
}
</style>

<script>
// import axios from 'axios';
import AddToBasketBtn from '../components/AddToBasketBtn.vue';

export default {
  name: 'Case',
  components: {
    AddToBasketBtn,
  },
  data() {
    return {
      item: null,
      parsedPreview: '',
      loader: null,
    };
  },
  methods: {
    parseCustomTags(line, formatTag, htmlTag) {
      const parts = line.split(formatTag);
      if (parts.length > 1) {
        line = parts[0];
        for (let i = 1; i < parts.length; i++) {
          line += (i % 2 == 1 ? `<${htmlTag}>` : `</${htmlTag}>`) + parts[i];
        }
        if (parts.length % 2 == 0) {
          line += `</${htmlTag}>`;
        }
      }
      return line;
    },
  },
  async mounted() {
    this.loader = this.$loading.show({});

    this.item = await this.$api
      .get(`/cases`, {
        params: {
          filters: {
            slug: {
              $eq: this.$route.params.id,
            },
          },
          populate: ['case_topics', 'books'],
        },
      })

      .then((response) => {
        const transformedData = this.$findOne.transform(response.data);
        console.log(transformedData);
        transformedData.type = 'case';

        return transformedData;
      });

    this.loader.hide();
  },
};
</script>
