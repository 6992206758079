<template>
  <div class="grid-container">
    <main class="main">
      <div class="main-header">
        <div>
          <h1 class="main-header__heading">{{ headerText.title }}</h1>
          <p class="main-header__description">{{ headerText.description }}</p>
        </div>
      </div>
      <pricing-table @select="selectBundle" v-if="step === 1" />
      <div class="payment-card" v-if="step === 2">
        <stripe-component :bundle="selectedBundle" @go-back="deselectBundle" />
      </div>
    </main>
  </div>
</template>

<script>
import StripeComponent from '@/components/Stripe.vue';
import PricingTable from '@/components/PricingTable.vue';

export default {
  name: 'Bundles',
  components: {
    'stripe-component': StripeComponent,
    'pricing-table': PricingTable,
  },
  data() {
    return {
      step: 1,
      selectedBundle: null,
      steps: [{ caption: 'Select Bundle' }, { caption: 'Payment' }],
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    headerText() {
      if (this.step === 1) {
        return {
          title: 'Wähle dein Paket',
          description: '',
        };
      } else if (this.step === 2) {
        const selectedBundleText = `Paket ${this.selectedBundle.title} | ${this.selectedBundle.price} € | ${this.selectedBundle.description}`;

        const lines = selectedBundleText.split('\n');
        const modifiedLines = lines.map((line, index) => {
          if (index === lines.length - 1) {
            return line; // Exclude the last line from modification
          }
          return line + ' |';
        });

        return {
          title: 'Payment',
          description: modifiedLines.join('\n'),
        };
      }

      return {
        title: '',
        description: '',
      };
    },
  },

  methods: {
    selectBundle(bundle) {
      this.selectedBundle = bundle;
      this.step = 2;
    },
    deselectBundle() {
      this.selectedBundle = null;
      this.step = 1;
    },
  },
  async mounted() {
    this.selectedBundle = this.$route.params.bundle;
    if (this.selectedBundle) {
      this.step = 2;
    } else {
      // Reset to the bundle selection step if no bundle is provided in the route
      this.step = 1;
    }
  },
};
</script>

<style lang="scss" scoped scss>
@import '../assets/css/_variables';

$base-border: 32px;
.grid-container {
  margin: 0 10%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'main';
}

.main {
  grid-area: main;
  margin-bottom: 3em;
}

.main-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: $base-border;
  padding: 20px 40px;
  height: 150px;
  background-color: white;
  border-radius: 8px;
  
  @media screen and (max-width: 640px) {
      padding: 20px;
      margin: 5px;
      height: auto;
    }
    
  &__heading {
    color: #002060;
    
    @media screen and (max-width: 640px) {
      font-size: 1.7em;
    }
  }
  &__description {
    /* font-size: 24px; */
  }
  &__getBackButton {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
}

.payment-card {
  display: flex;
  justify-content: center;
}
</style>
