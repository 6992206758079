<template>
  <div
    :class="{
      active: isActive,
      'message--success': status === 'success',
      'message--failure': status === 'failure',
    }"
    class="message"
  >
    <svg viewBox="0 0 76 76" class="message__icon icon-checkmark">
      <circle ref="circle" cx="38" cy="38" r="36" />
      <path
        ref="path"
        :d="status === 'success' ? successPath : failurePath"
        fill="none"
        stroke="#FFFFFF"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
      />
    </svg>
    <h1 class="message__title">{{ title }}</h1>
    <div class="message__content">
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'success', // or 'failure'
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      svg: null,
      successPath: 'M17.7,40.9l10.9,10.9l28.7-28.7', // SVG path for success (checkmark)
      failurePath: 'M24,52l28-28 M52,52L24,24', // SVG path for failure (cross)
    };
  },
};
</script>

<style lang="scss" scoped>
$success__primary: #3dc480;
$failure__primary: #d9534f;
$secondary: #b8babb;

.message {
  text-align: center;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__icon {
    max-width: 75px;
  }
  &__title,
  &__content {
    transform: translateY(25px);
    transition: all 200ms ease;
  }
  &__content {
    transition-delay: 50ms;
  }
  .icon-checkmark {
    circle {
      transform-origin: 50% 50%;
      transform: scale(0);
      transition: transform 200ms cubic-bezier(0.22, 0.96, 0.38, 0.98);
    }
    path {
      transition: stroke-dashoffset 350ms ease;
      transform: scale(0);
      transition-delay: 100ms;
    }
  }
  &.active {
    &__title,
    &__content {
      transform: translateY(0);
      opacity: 1;
    }
    .icon-checkmark circle,
    path {
      transform: scale(1);
    }
  }
  &--success {
    &__title,
    .icon-checkmark circle {
      fill: $success__primary;
    }
    &__content {
      color: $secondary;
    }
  }
  &--failure {
    &__title,
    .icon-checkmark circle {
      fill: $failure__primary;
    }
    &__content {
      color: $secondary;
    }
  }
}
</style>
