<template>
  <div>
    <!-- <h1>Payment Status</h1> -->
    <!-- <div id="payment-message"></div> -->
    <PaymentMessage
      v-if="message"
      :key="message.key"
      :isActive="message.isActive"
      :status="message.status"
      :title="message.title"
      :content="message.content"
    />
  </div>
</template>

<script>
import PaymentMessage from '../components/PaymentMessage.vue';

export default {
  components: {
    PaymentMessage,
  },
  data() {
    return {
      message: null,
    };
  },
  mounted() {
    this.checkStatus();
  },
  methods: {
    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) {
        return;
      }

      await this.$loadStripe().then(async (stripeInstance) => {
        const { paymentIntent } = await stripeInstance.retrievePaymentIntent(
          clientSecret
        );

        switch (paymentIntent.status) {
          case 'succeeded':
            this.showMessage('Payment succeeded!', 'success');

            break;
          case 'processing':
            this.showMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            this.showMessage(
              'Your payment was not successful, please try again.',
              'failure'
            );
            break;
          default:
            this.showMessage('Something went wrong.', 'failure');
            break;
        }
        await this.$store.dispatch('fetchAndUpdateUserData');
      });
    },
    showMessage(messageText, status) {
      this.message = {
        key: Date.now(),
        status: status,
        title: messageText,
        content: '',
        isActive: false,
      };

      setTimeout(() => {
        this.message.isActive = true;
      }, 350);
    },
  },
};
</script>

<style scoped>
#payment-message {
  font-size: 18px;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-top: 20px;
}
</style>
