<template>
  <form id="payment-form" @submit.prevent="handleSubmit">
    <div id="payment-element"></div>
    <div class="payment-actions">
      <button @click="$emit('go-back')" class="leave">Zurück</button>
      <button id="submit" :disabled="isLoading" class="paynow">
        <!-- <div class="spinner" v-show="isLoading" id="spinner"></div> -->
        <span id="button-text">Jetzt bezahlen</span>
      </button>
    </div>

    <div id="payment-message" class="hidden"></div>
  </form>
</template>

<script>
export default {
  props: {
    bundle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stripe: null,
      isLoading: false,
      elements: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.$loadStripe().then(async (stripeInstance) => {
        this.stripe = stripeInstance;

        //Using Custom Api Plugin Here
        await this.$api
          .post(
            '/payments/stripe',
            {
              items: [this.bundle],
            },
            {
              customData: {
                userToken: this.user?.jwt ?? '',
              },
            }
          )
          .then((response) => response.data)
          .then(({ clientSecret }) => {
            const appearance = {
              theme: 'stripe',
            };
            this.elements = this.stripe.elements({ appearance, clientSecret });

            const paymentElementOptions = {
              layout: 'tabs',
            };

            const paymentElement = this.elements.create(
              'payment',
              paymentElementOptions
            );
            paymentElement.mount('#payment-element');
          });
      });
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.setLoading(true);

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${this.$baseURL}/status`,
          receipt_email: this.user?.email,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.showMessage(error.message);
      } else {
        this.showMessage('An unexpected error occurred.');
      }

      this.setLoading(false);
    },

    showMessage(messageText) {
      const messageContainer = document.querySelector('#payment-message');

      messageContainer.classList.remove('hidden');
      messageContainer.textContent = messageText;

      setTimeout(function () {
        messageContainer.classList.add('hidden');
        messageContainer.textContent = '';
      }, 4000);
    },
    setLoading(isLoading) {
      this.isLoading = isLoading;
    },
  },
};
</script>

<style scoped>
.payment-actions .leave{
  padding: 13px 45px;
  background: #fff;
  color: #002060;
}

.payment-actions .paynow{
  padding: 13px 45px;
}

.payment-actions {
  margin: 20px;
  display: flex;
  justify-content: space-between;
}

/* Variables */
* {
  box-sizing: border-box;
}

form {
  width: 50vw;
  min-width: 500px;
  align-self: center;
  border-radius: 7px;
  padding: 40px;
  margin-bottom: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 1100px) {
  .payment-actions {
    flex-flow: column;
  }
  .payment-actions button {
    margin: 15px 0;
  }
}
@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>
