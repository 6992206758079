<template>
  <svg
    :class="cssClasses"
    :style="{ fill: color }"  
    aria-hidden="true"
    v-bind="$attrs"
  >
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script>
import sprite from '@/assets/svg/sprite.svg';

export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    cssClass: {
      type: String,
      default: ''
    },
    color: { 
      type: String,
      default: '#002060'  
    }
  },
  computed: {
    iconPath() {
      return `${sprite}#${this.icon}`;
    },
    cssClasses() {
      return {
        'svg-icon': true,
        [this.cssClass]: this.cssClass.length > 0
      };
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 2.5rem;
  height: 2.5rem;
  /* Removed the "fill" property from here because it's now handled in inline styles */
}
</style>