import Vue from 'vue';
import App from './App.vue';
import './globalMixin';
import router from './router';
import store from './store';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import TreeView from 'vue-json-tree-view';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import LogRocket from 'logrocket';
import VModal from 'vue-js-modal';
import StripePlugin from './plugins/stripe';
import axiosPlugin from './plugins/axios';
import Spinner from './components/Spinner.vue';
import SvgIcon from '@/components/SvgIcon.vue';

Vue.component('spinner', Spinner);
Vue.component('svg-icon', SvgIcon);

Vue.config.productionTip = false;
Vue.use(VueToast);
Vue.use(TreeView);
Vue.use(Loading, {
  color: '#4285f4',
  backgroundColor: '#edf4fe',
  loader: 'dots',
  width: 100,
  opacity: 1,
});
Vue.use(VModal);
Vue.use(axiosPlugin);
Vue.use(StripePlugin);


const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (vue.$store.state?.cookies?.lr) LogRocket.init('3vyzuv/heyjura');
