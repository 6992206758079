module.exports = {
  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  downloadB64PDF(data, filename) {
    console.log('Creating <a> element');
    const downloadLink = document.createElement('a');
    downloadLink.href = data;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    setTimeout(() => {
      console.log('Triggering click on <a> element');
      downloadLink.click();
      setTimeout(() => {
        console.log('Removing <a> element from the DOM');
        downloadLink.remove();
      }, 1000);
    }, 500);
  },
};
