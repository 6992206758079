<template>
  <modal name="cookies" :height="500" :adaptive="true">
    <img src="../assets/img/cookie.png" alt="Cookie">

    <p>Mit Hilfe von Cookies möchten wir sicherstellen, dass Du die bestmögliche User-Experience auf unserer Website hast. Cookies ermöglichen uns insbesondere technische Fehler besser erkennen und schneller beseitigen zu können. Daneben dienen sie der Analyse von absolut anonymen Nutzerstatistiken. So wissen wir, was Euch gut gefällt. Klicke bitte zum Aktivieren der Cookies auf "Akzeptieren". Bitte lies auch unsere <router-link to="/privacy" @click.native="close" class= "linkDatenschutz">Datenschutzerklärung</router-link>.</p>

    <div class="option">
      <span>Anonyme Nutzerstatistiken:</span>
      <button :class="{disabled: !ga}" @click="ga=!ga">{{!ga ? "Akzeptieren" : "Deaktivieren"}}</button>
    </div>

    <div class="option">
      <span>Technische Fehlerbehebung:</span>
      <button :class="{disabled: !lr}" @click="lr=!lr">{{!lr ? "Akzeptieren" : "Deaktivieren"}}</button>
    </div>

    <div class="center">
      <button class="close" @click="() => {close(); save();}">Akzeptieren</button>
    </div>
  </modal>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

img {
  height: 3em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.option button {
  font-size: 0.7em;
  padding: 0.6em 1em;
  background: #f5f7ff;
  color: $darkBlue;
  border: 3px solid #002060;
  font-weight: 600;
  
   @media screen and (max-width: 640px) {
   font-size: 0.6em;
  }
  
  &.disabled{
    /* background: $grey; */
    color: #4285f4;
    border: 3px solid #4285f4; 
  }
}

.option button:hover {
	color: rgb(9, 125, 239);
	border-color: rgb(9, 125, 239);
	//fehler! border-color: background: rgb(9, 125, 239);
}

.option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  margin-bottom: 1em;
}

.option span{
	font-size: 16px;
	font-weight: bold;
	
	@media screen and (max-width: 640px) {
	font-size: 14px;
  }
}

.center{
  display: flex;
  justify-content: center;
}

.close{
  margin-top: 0.6em;
  padding: 0.6em 2em;
  font-size: 20px;
  background: #4285f4;
  color: #fff;
  font-weight: 600;
  
  @media screen and (max-width: 640px) {
  font-size: 16px;
  padding: 0.8em 2em;
  }
}

.close:hover{
	background-color: rgb(9, 125, 239);
	color: #fff;
}

.linkDatenschutz{
	text-decoration: none; 
	color: #4285f4; 
	font-weight: 600;
}

.linkDatenschutz:hover{
	color: rgb(9, 125, 239);
}

</style>

<style lang="scss">
.vm--modal {
  background: #f5f7ff !important;
  @media screen and (max-width: 640px) {
   padding: 1em 1em; 
   top: 50px !important;
   font-size: 14px;
   // not needed since small displays (primarily smartphones) cannot display properly height: 650px !important;
  }
  padding: 1em 4em;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.14);
}

.vm--overlay {
  background: rgba(0, 0, 0, 0.7) !important;
}
</style>

<script>
export default {
  name: "Cookies",
  data(){
    return {
      ga: true,
      lr: true
    };
  },
  methods: {
    save(){
      this.$store.commit("acceptCookies", {ga: this.ga, lr: this.lr});
    },
		close(){
      this.$modal.hide("cookies");
    },
    show(){
      this.$modal.show("cookies");
    }
  }
}
</script>
