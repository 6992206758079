<template>
  <div class="item">
    <span v-if="['topic'].includes(data.type)" @click="preview(data)">
      <Arrow :direction="showChildren ? 'up' : 'down'" />
    </span>
    <div class="flex">
      <div>
       <h3 v-if="['topic'].includes(data.type)" @click="preview(data)">{{ data.title }}</h3>
        <h3 v-else>
          <router-link :to="getPath(data)" class="title-link">{{ data.title }}</router-link>
        </h3>
        <h4>
          {{
            data.type == 'topic'
              ? 'Definitionen'
              : data.type == 'schema'
              ? 'Schema'
              : data.type == 'case'
              ? data.case_topics && data.case_topics.length
                ? data.case_topics.map((topic) => topic.title).join(', ')
                : 'Falle'
              : 'Definition'
          }}
        </h4>
      </div>
      <AddToBasketBtn :item="data" v-if="['schema', 'topic', 'case'].includes(data.type)" />
    </div>

    <div v-if="data.type == 'definition'" v-html="data.description" class="description"></div>
    <div v-if="['topic'].includes(data.type)" class="children" :class="{ show: showChildren }">
      <div v-for="(child, index) in data.definitions" :key="index">
        <div class="flex">
          <h3>
            <router-link :to="getPath(child, 'definition')" class="title-link">{{
              child.title
            }}</router-link>
          </h3>
        </div>
        <div v-html="child.description" class="description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from './Arrow.vue';
import AddToBasketBtn from './AddToBasketBtn.vue';

export default {
  name: 'SearchResult',
  components: {
    Arrow,
    AddToBasketBtn,
  },
  props: ['data', 'closed'],
  data() {
    return {
      showChildren: !this.closed,
    };
  },
  methods: {
    preview(document, type) {
      if (type) {
        document.type = type;
      }
      this.showChildren = !this.showChildren;
    },
    getPath(document, type) {
      if (type) {
        document.type = type;
      }
      if (document.type == 'schema') {
        return '/schema/' + document.slug;
      }
      if (document.type == 'definition') {
        return '/definition/' + document.slug;
      }
      if (document.type == 'case') {
        return '/case/' + document.slug;
      }
     
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/_variables';

.item {
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  color: $darkBlue;
  margin-left: 35px;
  margin-bottom: 1em;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  h3 {
    font-size: 1.5em;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    overflow-wrap: anywhere;
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }

  p {
    margin: 0px;
    width: 90%;
  }
    .description {
    ::v-deep p {
      margin: 0 !important;
      width: 90%;
    }
  }

  span {
    position: absolute;
    left: -35px;
    top: 3px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .children {
    display: none;

    & > div {
      margin-top: 1em;
    }

    h3 {
      font-size: 1.25em;
    }
  }

  .show {
    display: block;
    margin-bottom: 2em;
  }
  .title-link {
    margin: 0px;
    color: inherit;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
