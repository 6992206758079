<template>
  <div>
    <!-- <div>{{ $store.state.lastRoute }}</div>
    <p>{{ text }}</p> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: 'Loading...',
      loader: null,
    };
  },
  props: ['providerName'],
  methods: {
    async fetchLogin() {
      try {
        const queryString = window.location.search;

        const response = await this.$api.get(
          `/auth/${this.providerName}/callback${queryString}`,
          { params: {} }
        );

        console.log('Response:', response);

        if (response.status !== 200) {
          throw new Error(
            `Couldn't login to Strapi. Status: ${response.status}`
          );
        }
        const res = response.data;
        console.log('Response Data:', res);

        this.$store.dispatch('saveUserData', {
          jwt: res.jwt,
          username: res.user.username,
          ...res.user,
        });

        this.text =
          'You have been successfully logged in. You will be redirected in a few seconds...';

        // Redirect to last visited page or dashboard (if the user hasn't agreed to terms)
        if (res.user.agreeOnTerms) {
          // Get the last visited page from sessionStorage
          const lastVisitedPage = sessionStorage.getItem('lastRoute');
          console.log(lastVisitedPage);
          this.$router.push(lastVisitedPage || '/');
          // Remove the lastRoute from sessionStorage
          sessionStorage.removeItem('lastRoute');
          if (this.loader) {
            this.loader.hide();
          }
        } else {
          this.$router.push('/dashboard');
          if (this.loader) {
            this.loader.hide();
          }
        }
      } catch (err) {
        console.log('Error:', err);
        this.text = 'An error occurred, please see the developer console.';
      } finally {
        if (this.loader) {
          this.loader.hide();
        }
      }
    },
  },
  mounted() {
    this.loader = this.$loading.show({}); // Open the loader when the page is mounted
  },
  created() {
    this.fetchLogin();
  },
};
</script>
