import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Basket from '../views/Basket.vue';
import Admin from '../views/Admin.vue';
import FullSearch from '../views/FullSearch.vue';
import CasesFullSearch from '../views/CasesFullSearch.vue';
import PageNotFound from '../views/PageNotFound.vue';
import SchemePreview from '../views/SchemePreview.vue';
import Definition from '../views/Definition.vue';
import Case from '../views/Case.vue';
import Imprint from '../views/Imprint.vue';
import Copyright from '../views/Copyright.vue';
import Goals from '../views/Goals.vue';
import Privacy from '../views/Privacy.vue';
import Contact from '../views/Contact.vue';
import Login from '../views/Login.vue';
import LoginRedirect from '../views/LoginRedirect';
import Terms from '../views/Terms';
import Dashboard from '../views/Dashboard.vue';
import Bundles from '../views/Bundles.vue';
import Status from '../views/Status.vue';
import TypeForm from '../views/TypeForm.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/fullSearch',
    name: 'FullSearch',
    component: FullSearch,
  },
  {
    path: '/casesFullSearch',
    name: 'CasesFullSearch',
    component: CasesFullSearch,
  },
  {
    path: '/basket',
    name: 'Basket',
    component: Basket,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
  },
  {
    path: '/schema/:id',
    name: 'SchemePreview',
    component: SchemePreview,
  },
  {
    path: '/definition/:id',
    name: 'Definition',
    component: Definition,
  },
  {
    path: '/case/:id',
    name: 'Case',
    component: Case,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/copyright',
    name: 'Copyright',
    component: Copyright,
  },
  {
    path: '/goals',
    name: 'Goals',
    component: Goals,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/connect/:providerName/redirect',
    name: 'loginRedirect',
    component: LoginRedirect,
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/bundles',
    name: 'Bundles',
    component: Bundles,
    meta: { requiresAuth: true },
  },
  {
    path: '/status',
    name: 'Status',
    component: Status,
  },
  {
    path: '/registration-form',
    name: 'TypeForm',
    component: TypeForm,
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;

  // store the last visited route
  if (to.name !== 'Login' && from.name !== 'Login' && from.name !== 'Dashboard' && from.name !== null) {
    store.commit('setLastRoute', from.path);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      if (to.path === '/dashboard') {
        next({
          path: '/login',
          query: { redirect: store.state.lastRoute || '/' },
        });
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath },
        });
      }
    } else {
      const user = store.getters.getUser;
      // Update user data with latest version if the user exists
      if (user) {
        await store.dispatch('fetchAndUpdateUserData');
      }

      if (to.fullPath === '/dashboard') {
        if (!user.agreeOnTerms) {
          next('/login/terms');
          } else if (!user.registrationFormCompleted) {
            next('/registration-form');
        } else {
          next();
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
