<template>
  <div>
    <section v-if="documentTree">
      <h1>Admin Upload</h1>

      <div class="admin">
        <h2>Vorhandene Dokumente</h2>
        <tree-view
          :data="documentTree"
          :options="{ rootObjectKey: 'Dokumente', maxDepth: 6 }"
        ></tree-view>

        <!--
          <table>
            <tr>
              <th>Name</th>
              <th>Typ</th>
              <th>Beschreibung</th>
              <th>Hierarchie</th>
            </tr>
            <tr v-for="doc in documents" :key="doc._id">
              <td>{{ doc.name }}</td>
              <td>{{ doc.type }}</td>
              <td>{{ doc.description }}</td>
              <td>{{ doc.tree }}</td>
              <td class="remove" @click="removeDocument(doc)">X</td>
            </tr>
          </table>
          -->

        <h2>Neues Dokument hochladen</h2>
        <input type="text" placeholder="Name" v-model="name" />
        <textarea
          placeholder="Beschreibung"
          cols="41"
          rows="5"
          v-model="description"
        ></textarea>

       <textarea
          v-show="type == 'Schema'"
          placeholder="Preview"
          cols="41"
          rows="5"
          v-model="preview"
        ></textarea>

        <input type="text" placeholder="parentID" v-model="parentID" />

        <div>
          <input type="radio" id="type1" value="Definition" v-model="type" />
          <label for="type1">Definition</label>
          <input type="radio" id="type2" value="Schema" v-model="type" />
          <label for="type2">Schema</label>
          <input type="radio" id="type3" value="root" v-model="type" />
          <label for="type3">root</label>
        </div>
        <input type="file" ref="file" />
        <button @click="upload">Upload</button>

        <h2>Dokument löschen</h2>
        <input type="text" placeholder="id" v-model="deleteID" />
        <button @click="deleteDocument">Delete</button>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/css/_variables";

h1 {
  margin: 50px 0px;
  font-size: 50px;
  text-align: center;
  color: $darkBlue;
}

h4 {
  margin: 0px;
}

label {
  margin-right: 20px;
}

textarea {
  display: block;
}

input[type="text"] {
  width: 300px;
}

.remove {
  cursor: pointer;
  font-weight: bold;
}

.admin {
  padding: 2%;
}

td {
  border: 1px solid $darkBlue;
}

button {
  padding: 8px 20px;
}
</style>

<script>
const { toBase64 } = require("../assets/js/util");
import axios from "axios";

export default {
  name: "Admin",
  data() {
    return {
      name: "",
      type: "Definition",
      description: "",
      preview: "",
      parentID: "",
      documentTree: null,
      deleteID: "",
      axiosOpts: {},
    };
  },
  mounted() {
    let apiKey = this.$store.state.apiKey;
    if(!apiKey){
      apiKey = prompt("Admin Passwort:");
      this.$store.commit("setAdminAPIKey", apiKey);
    }
    this.axiosOpts = {headers: {"api-key": apiKey}};
    this.loadDocumentTree();
  },
  methods: {
    async loadDocumentTree() {
      this.documentTree = (
        await axios.get(`${process.env.VUE_APP_BACKEND_URL}/admin/getDocumentTree`, this.axiosOpts)
      ).data;
    },
    async upload() {
      const data = (
        await axios.post(`${process.env.VUE_APP_BACKEND_URL}/admin/uploadPDF`, {
          file: this.$refs.file.files[0]
            ? await toBase64(this.$refs.file.files[0])
            : "",
          name: this.name,
          type: this.type,
          description: this.description,
          preview: this.preview,
          parentID: this.parentID,
        }, this.axiosOpts)
      ).data;

      if (data.success) {
        this.$toast.open("Upload erfolgreich");
        this.name = "";
        this.description = "";
        this.preview = "";

        this.loadDocumentTree();
      } else {
        this.$toast.open({
          message: "Upload fehlgeschlagen",
          type: "error",
        });
      }
    },
    async deleteDocument() {
      const data = (await axios.post(`${process.env.VUE_APP_BACKEND_URL}/admin/deleteDocument`, {
        id: this.deleteID,
      }, this.axiosOpts)).data;

      if (data.success) {
        this.$toast.open(`"${this.deleteID}" erfolgreich gelöscht`);
        this.loadDocumentTree();
      } else {
        this.$toast.open({
          message: `Löschen von "${this.deleteID}" fehlgeschlagen`,
          type: "error",
        });
      }
    },
  },
};
</script>
