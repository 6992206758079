<template>
  <div class="pricing-table">
    <div
      class="bundle"
      v-for="(bundle, index) in bundles"
      :key="bundle.id"
      @click="selectBundle(bundle)"
      :class="{ 'bundle-2': index === 1 }"
    >
      <!-- Add badge if index is 1 (2nd card) -->
      <div class="badge" v-if="index === 1">-20% 🔥</div>

      <h2>{{ bundle.title }}</h2>
      <p>{{ bundle.price }} €</p>
        
      <div class="description">{{ bundle.description }}</div>
      <button v-if="index !== 1">Jetzt kaufen</button>
      <!-- Regular button -->
      <button class="calltoaction" v-else>Jetzt kaufen 🚀</button>
      <!-- Button for 2nd card -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingTable',
  data() {
    return {
      bundles: [],
    };
  },
  methods: {
    selectBundle(bundle) {
      this.$emit('select', bundle);
    },
    async fetchBundles() {
      try {
        const response = await this.$api.get('/plans');
        console.log(response);
        this.bundles = this.$findMany.transform(response.data);
      } catch (error) {
        console.error('Error fetching bundles:', error);
      }
    },
  },
  async mounted() {
    await this.fetchBundles();
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/css/_variables';

.pricing-table {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 80px 0px;
  position: relative;
  
  @media screen and (max-width: 640px) {
      margin-top: 0px;
    }
}

.bundle {
  flex: 1 1 200px;
  border-radius: 5px;
  padding: 30px;
  margin: 10px;
  text-align: center;
  position: relative;
  z-index: 1;
  
  /* giving bugs on iphone
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-content: center;*/
  
  @media screen and (max-width: 640px) {
      margin-bottom: 40px;
      padding: 40px 20px;
    }
}

.bundle-2 {
  box-shadow: 0 10px 70px rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 30px;
  height: 400px;
  margin: 0 -50px; /* Increase the negative margin */

  @media screen and (max-width: 992px) {
      width: 80%;
      margin: 0px auto;
    }
}

.badge {
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: #4285f4;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
}
  
.bundle h2 {
  color: #002060;
  margin: 0;
  font-size: 3em;
  text-align: center;
  color: $darkBlue;

  @media screen and (max-width: 640px) {
    font-size: 1.75em;
  }
}

.bundle p {
  text-align: center !important;
  font-size: 24px;
  color: #002060;
  font-weight: 600;
}

.bundle {
  .description {
    color: #002060;
    margin: 0 0 20px 0;
    list-style: none;
    padding-left: 0;
    text-align: center;
    line-height: 2em;
    white-space: pre-line;
  }
}

.bundle button {
  background-color: #002060;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 13px 45px;
  /*width: 80%; */
}

button {
  text-align: center;
  background: $blue;
  color: white;
  border: none;
  border-radius: 33px;
  font-size: 20px;
  cursor: pointer;

  transition: transform 0.3s;

  &:hover {
    background: #097def;
  }

  &:active {
    transform: translateY(5px);
  }

  @media screen and (max-width: 640px) {
    font-size: 1em;
  }
}

.bundle button:hover {
  background-color: #4285f4;
}

.bundle .calltoaction {
  background-color: #4285f4;
}

.bundle .calltoaction:hover {
  background-color: #002060;
}

.pricing-text {
  max-width: 50%;
  margin: auto;
}

.pricing-text p {
  color: #002060;
  font-size: 21px;
  text-align: left;
  margin: 0;
}

.free-features p {
  margin-top: 20px;
}

@media (max-width: 992px) {
  .pricing-table {
    flex-direction: column;
  }

  .bundle {
    margin-top: 20px;
  }

  .bundle-2 {
    /* margin: 50px 0; Increase the negative margin */
  }

  .badge {
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
