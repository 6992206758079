<template>
  <div class="grid-container">
    <!-- <header class="header">
      <div class="header__search"></div>
      <div class="header__avatar">logout</div>
    </header> -->

    <main v-if="user" class="main">
      <div class="main-header">
        <div class="main-header-content">
          <h1 class="main-header__heading">Hi 👋</h1>
          <p class="main-header-subtitle">
            Hier zeigen wir Dir die Anzahl Deiner herunterladbaren Lerneinheiten an.
          </p>
        </div>
        <!-- <div class="main-header__getBackButton">
          <router-link to="/home">
            <button>Los geht's</button>
          </router-link>
        </div> -->
      </div>

      <div class="main-overview">
        <div class="overviewcard">
          <div class="overviewcard__icon">
            <div>{{ user.tokens }}</div>
          </div>
          <div class="overviewcard__info">
            <h3>Herunterladbare Lerneinheiten</h3>
          </div>
        </div>
        
        <!-- <div class="overviewcard">
          <div class="overviewcard__icon">
            <div>100</div>
          </div>
          <div class="overviewcard__info">
            <h3>Zur Verfügung stehenden Tokens</h3>
          </div>
        </div> -->
        <div class="overviewcard--transparent">
          <router-link to="/bundles">
            <button class="calltoaction_bundles">Mehr kaufen</button>
          </router-link>

          <!-- <div class="overviewcard__icon">
            <div>1000</div>
          </div>
          <div class="overviewcard__info">
            <h3>Zur Verfügung stehende Tokens</h3>
          </div> -->
        </div>
      </div>

      <div class="actions-cards">
        <div class="overviewcard--transparent logout">
          <button @click="logout">Logout</button>
        </div>
        <!-- <div class="card"><h3>History</h3></div>
        <div class="card"><div class="premium">Become Premium</div></div>
        <div class="card">Some Hook</div> -->
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  methods: {
    logout() {
      this.$store.commit('setUser', null);
      this.$router.push('/');
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style lang="scss" scoped scss>
@import '../assets/css/_variables';

$base-border: 32px;
/* Assign grid instructions to our parent grid container, mobile-first (hide the sidenav) */
.grid-container {
  margin: 0 10%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    'header'
    'main';
}

/* Give every child element its grid name */
.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $base-border;
  background-color: white;
  border-bottom: 1px solid #ccc;
}

/* Make room for the menu icon on mobile */
.header__search {
  margin-left: 42px;
}

.main {
  grid-area: main;
  max-width: 960px;
  margin: 0px auto;
}

.main-header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: $base-border;
  padding: 20px;
  height: 150px;
  border-radius: 8px;
  
  @media screen and (max-width: 640px) {
          margin: 0;
        }
        
  &__heading {
    color: #002060;
    font-size: 40px;
    
    @media screen and (max-width: 640px) {
          font-size: 1.7em;
        }
  }
  &__getBackButton {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
}

.main-header-content{
  margin: 0px auto;
}

.main-header-subtitle{
  margin: 0;
  color: #002060;
  font-size: 21px;
  
  @media screen and (max-width: 640px) {
          font-size: 16px;
        }
}

.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: 94px;
  grid-gap: $base-border;
  margin: $base-border;
  
  @media screen and (max-width: 640px) {
          margin: 0;
        }
}

.overviewcard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  background: #fff;
  height: 100%;
  box-shadow: 0 10px 70px rgba(0,0,0,0.04);

  &--transparent {
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    line-height: 134px;
  }
  &--transparent button{
    padding: 15px 45px;
  }
  &__info {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 0px;
    
  }
  &__info h3 {
    font-weight: 100;
    margin: 0;
    color: #002060;
    font-size: 21px;
    
    @media screen and (max-width: 640px) {
          font-size: 16px;
        }
  }
  &__icon {
    min-width: 100px;
    font-size: 3em;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    color: $darkBlue;
    border-radius: 8px;
    margin-right: 20px;
    
    @media screen and (max-width: 640px) {
          font-size: 2em;
          min-width: 0px;
        }
  }
}

.overviewcard--transparent .calltoaction-bundles{
   background: #4285f4;
   color: #fff;
 }

.overviewcard__info h3{
  color: #002060;
}

.main-cards {
  column-count: 1;
  column-gap: $base-border;
  margin: $base-border;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
  margin-bottom: $base-border;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 8px;
}

/* Force varying heights to simulate dynamic content */
.card:first-child {
  height: 485px;
}

.card:nth-child(2) {
  height: 200px;
}

.card:nth-child(3) {
  height: 265px;
}

.premium {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 2em;
  font-weight: bold;
  background-color: $background;
  border-radius: 8px;
}

.actions-cards {
  column-count: 1;
  column-gap: $base-border;
  margin-top: 200px;
  margin-bottom: 200px;
}

.actions-cards .logout button{
  background: #fff;
  color: $darkBlue;
}

/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .main-cards {
    column-count: 2;
  }
}
</style>
