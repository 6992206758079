<template>
  <div class="item">
    <span v-if="data.content.length && data.type != 'schema'" @click="preview(data)">
      <Arrow :direction="showChildren ? 'up' : 'down'" />
    </span>

    <div class="flex">
      <div>
        <router-link
          v-if="['schema', 'definition', 'case'].includes(data.type)"
          :to="getPath(data)"
          :class="{
            'title-link': true,
            root: data.type == null || data.type == 'code' || data.type == 'book',
          }"
        >
          <h3>
            {{ data.title }}
          </h3>
        </router-link>

        <h3
          v-else
          :class="{
            root: data.type == null || data.type == 'code' || data.type == 'book',
          }"
          @click="preview(data)"
        >
          {{ data.title }}
        </h3>

        <div v-if="['schema', 'topic', 'case'].includes(data.type)">
          <h4 v-if="data.content.length == 0 && data.type == 'definition'">
            {{ data.type }}
          </h4>
          <h4 v-else>
            {{
              data.type == 'topic'
                ? 'Definitionen'
                : data.type == 'schema'
                ? 'Schema'
                : data.type == 'case'
                ? data.case_topics && data.case_topics.length
                  ? data.case_topics.map((topic) => topic.title).join(', ')
                  : 'Falle'
                : 'Unknown Type'
            }}
          </h4>
        </div>
      </div>
      <AddToBasketBtn :item="data" v-if="['schema', 'topic', 'case'].includes(data.type)" />
    </div>

    <div v-if="data.description" v-html="data.description"></div>

    <div
      v-if="data.content.length"
      class="children"
      :class="{ show: showChildren, noIndent: data.type == 'topic' }"
    >
      <FullHierarchySearchResult
        v-for="child in data.content"
        :data="{ ...child, content: child.content || [] }"
        :key="child.id"
      />
    </div>
  </div>
</template>

<script>
import AddToBasketBtn from './AddToBasketBtn.vue';
import Arrow from './Arrow.vue';

export default {
  name: 'FullHierarchySearchResult',
  components: {
    AddToBasketBtn,
    Arrow,
  },
  props: ['data'],
  data() {
    return {
      showChildren: false,
      bookData: null,
    };
  },

  methods: {
    preview() {
      this.showChildren = !this.showChildren;
    },
    getPath(document) {
      if (document.type == 'schema') {
        return '/schema/' + document.slug;
      }
      if (document.type == 'definition') {
        return '/definition/' + document.slug;
      }
      if (document.type == 'case') {
        return '/case/' + document.slug;
      }
    
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/_variables';

.item {
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  margin-top: 1em;
  width: 100%;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  color: $darkBlue;

  h3 {
    font-size: 1.25em;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    overflow-wrap: anywhere;

    &.root {
      font-size: 1.5em;
    }
  }

  h4 {
    margin: 0px;
    opacity: 0.4;
  }

  span {
    position: absolute;
    left: -25px;
    top: 5px;
    cursor: pointer;
    user-select: none;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    overflow-wrap: break-word;
  }

  .children {
    display: none;
    padding-left: 2em;
    
    @media screen and (max-width: 640px) {
     padding-left: 10px;
    }

    &.noIndent {
      padding-left: 0px;
    }

    p {
      width: 90%;
      margin: 0px;
    }
  }

  .show {
    display: block;
    margin-bottom: 2em;
  }

  .title-link {
    margin: 0px;
    color: inherit;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &.root {
      font-size: 1.5em;
    }
  }
}
</style>
