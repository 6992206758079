<template>
  <div class="search">
    <SearchBox
      class="searchBox"
      @searchQueryChange="onSearchQueryChange"
      :hierarchySearch="true"
      :hideFilters="hideFilters"
    />
    <div>
      <!-- <spinner v-if="loading"></spinner> -->
      <div class="searchResults">
        <FullHierarchySearchResult
          v-for="searchResult in processedSearchResults"
          :key="searchResult.id"
          :data="searchResult"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchBox from '../components/SearchBox.vue';
import FullHierarchySearchResult from '../components/FullHierarchySearchResult.vue';

export default {
  name: 'FullHierarchySearch',
  data() {
    return {
      searchResults: [],
      searchQuery: {},
      searchType:
        this.type == 'cases' ? 'casesFullHierarchy' : 'searchFullHierarchy',
      loading: false,
      currentSearchType: '',
    };
  },
  components: {
    SearchBox,
    FullHierarchySearchResult,
  },
  props: ['type', 'hideFilters'],
  computed: {
    processedSearchResults() {
      let filteredResults = [];

      if (this.searchResults.length) {
        filteredResults = this.searchResults;
        return filteredResults.map((result) => this.addTypeToObject(result));
      } else {
        return [];
      }
    },
  },

  async mounted() {
    await this.onSearchQueryChange({ type: '' });
  },
  methods: {
    async onSearchQueryChange(event) {
      this.loading = true;

      try {
        this.searchQuery = event;
        this.currentSearchType = this.searchType;
        const response = await this.$api.post(`/search/${this.searchType}`, {
          type: event.type,
        });

        const transformedData = response.data;
        console.log(transformedData);
        this.searchResults = [...transformedData]; // or Array.from(transformedData)
      } catch (error) {
        console.log(error);
        this.searchResults = [];
        this.$toast.open({
          message: 'Suche fehlgeschlagen',
          type: 'error',
        });
      } finally {
        this.loading = false;
      }
    },

    addTypeToObject(obj) {
      const keyword = 'content';
      // Add the filtering for render 'casesFullHierarchy'
      if (this.currentSearchType === 'casesFullHierarchy') {
        obj.codes = obj.codes.filter((code) => {
          code.books = code.books.filter((book) => {
            // book.cases = book.cases.filter(lawCase => {
            //   // Include only those cases that have no topics
            //   return !lawCase.cases_topics || !lawCase.cases_topics.length;
            // });
            // If book.cases is now empty, filter it out
            return book.cases.length;
          });
          // If code.books is now empty, filter it out
          return code.books.length;
        });
      }

      obj.codes.forEach((code) => {
        code.type = 'code';

        code.books.forEach((book) => {
          book.type = 'book';

          // add type property to topics and schemata objects
          const addTypeToDefinitions = (definitions) => {
            definitions.forEach((definition) => {
              definition.type = 'definition';
            });
          };

          // define new content array
          const content = [];

          if (book.topics) {
            book.topics.forEach((topic) => {
              topic.type = 'topic';
              addTypeToDefinitions(topic.definitions);

              //change array name
              topic[keyword] = topic.definitions;
              delete topic.definitions;
            });
            // merge topics into a new content array
            content.push(...book.topics);
          }

          if (book.cases) {
            book.cases.forEach((lawCase) => {
              lawCase.type = 'case';
            });
            // merge cases into a new content array
            content.push(...book.cases);
          }

          if (book.schemata) {
            book.schemata.forEach((schema) => {
              schema.type = 'schema';
              addTypeToDefinitions(schema.definitions);

              //change array name
              schema[keyword] = schema.definitions;
              delete schema.definitions;
            });
            // merge schemata into a new content array
            content.push(...book.schemata);
          }

          // sort content array in alphabetical order based on title property
          content.sort((a, b) => {
            if (a.title && b.title) {
              return a.title.localeCompare(b.title);
            } else if (a.title) {
              return -1;
            } else {
              return 1;
            }
          });

          // merge content back into books object
          book[keyword] = content;
          delete book.topics;
          delete book.schemata;
          delete book.cases;
        });

        //change array name
        code[keyword] = code.books;
        delete code.books;
      });

      //change array name
      obj[keyword] = obj.codes;
      delete obj.codes;

      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  padding: 0px 4em;

  @media screen and (max-width: 640px) {
    padding: 0px 2em;
  }
}

.searchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
</style>
