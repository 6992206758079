<template>
  <div class="search">
    <SearchBox
      class="searchBox"
      @searchQueryChange="onSearchQueryChange"
      :showSuggest="showSuggest"
    />
    <!-- <spinner v-if="loading"></spinner> -->
    <div class="searchResults" v-if="matchedResults.length">
      <SearchResult
        v-for="(searchResult, index) in matchedResults"
        :key="index"
        :data="searchResult"
      />
    </div>
    <div class="searchResults" v-if="similarResults.length" closed>
      <h2>Ähnlich:</h2>
      <SearchResult
        v-for="(searchResult, index) in similarResults"
        :key="`${index}ss`"
        :data="searchResult"
        :closed="true"
      />
    </div>
  </div>
</template>
<script>
// import axios from 'axios';
import SearchBox from '../components/SearchBox.vue';
import SearchResult from '../components/SearchResult.vue';

export default {
  name: 'Search',
  data() {
    return {
      matchedResults: [],
      similarResults: [],
      loading: false,
      showSuggest: false,
    };
  },
  components: {
    SearchBox,
    SearchResult,
  },
  methods: {
    async onSearchQueryChange(event) {
      this.loading = true;
      if (event.searchQuery == '' || event.searchQuery.length < 3) {
        this.showSuggest = false;
        this.matchedResults = [];
        this.similarResults = [];
        return;
      }

      console.log(event);
      await this.$api
        .post('/search', {
          query: event.searchQuery,
          relatedSearch: true,
          type: event.type,
        })
        .then((response) => {
          console.log(response.data);
          const transformedData = response.data;

          this.matchedResults = transformedData.matched;
          this.similarResults = transformedData.related;

          // Update the visibility of the suggest button based on the new results
          // The suggest button should not be shown if there are matched results
          this.showSuggest =
            event.searchQuery.length >= 4 &&
            event.searchQuery.length <= 20 &&
            !this.matchedResults.length;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.matchedResults = [];
          this.similarResults = [];
          this.showSuggest = false; // Hide the suggest button on error
          this.$toast.open({
            message: 'Suche fehlgeschlagen',
            type: 'error',
          });
          this.loading = false;
        });
    },
  },

  async mounted() {},
};
</script>
<style lang="scss" scoped>
@import '../assets/css/_variables';

.search {
  padding: 0px 2em;
}

.searchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1em;
}

.searchBox {
  margin-bottom: 50px;

  @media screen and (max-width: 640px) {
    margin-bottom: 20px;
  }
}

h2 {
  color: $blue;
  position: relative;
  max-width: 800px;
  flex-basis: auto;
  flex-grow: 1;
  width: 100%;
  margin-left: 35px;
  margin-bottom: 1em;
}
</style>
