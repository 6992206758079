<template>
    <img :class="direction" src="../assets/img/arrow-down.png" alt="">
</template>

<style lang="scss" scoped>
    img {
        width: 16px;
        height: 16px;
    }

    .up {
        transform: rotate(180deg);
    }
</style>

<script>
export default {
    name: "Arrow",
    props: ["direction"]
}
</script>