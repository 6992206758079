<template>
  <div id="form"></div>
</template>
<script>
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';

export default {
  name: 'TypeForm',
  components: {},
  methods: {
    async submitForm() {
      try {
        const response = await this.$api.put(
          '/user/me',
          {
            registrationFormCompleted: true, // Change this to true since the user has completed the form
          },
          {
            customData: {
              userToken: this.$store.state.user?.jwt ?? '',
            },
          }
        );

        if (response.status === 200) {
          // Update the user data in the Vuex store
          await this.$store.dispatch('fetchAndUpdateUserData');

          // Wait for the component to update and navigate to the dashboard
         this.$nextTick(() => {
            if (this.$store.state.user.tokens) {
              this.$router.push('/dashboard');
            } else {
              this.$router.push('/bundles');
            }
          });
        } else {
          throw new Error('Failed to submit the form');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
  },
  mounted() {
    const email = this.$store.state.user?.email ?? null;
    const userId = this.$store.state.user?.id ?? null;
    const registrationFormCompleted =
      this.$store.state.user?.registrationFormCompleted ?? false;

    if (registrationFormCompleted) {
      this.$router.push('/dashboard');
    }

    if (email && userId) {
      const { unmount } = createWidget('qvUpio7v', {
        container: document.querySelector('#form'),
        hidden: { email: email, user_id: userId },
        disableScroll: true,
        onSubmit: () => {
          unmount();
          this.submitForm();
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#form {
  height: 100vh;
  z-index: 999;
}
.box {
  padding: 10em 5em;
  background-color: #ffffff;
  border-radius: 8px;
}
.wrapper-terms {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 90%;
  height: 50%;
  border-radius: 8px;
  margin: 5%;
  padding: 0;
}
.wrapper-terms-text {
  display: flex;
  flex-direction: column;
  width: 362px;
  height: 278px;
  border-radius: 8px;
  margin: auto;
}
.terms-tittle {
  text-align: center;
  color: #002060;
  font-size: 2.5em;
}
.terms-tittle-2 {
  text-align: center;
  color: #002060;
}
.login-button-terms {
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 250px;
  height: 60px;
  font-size: 1em;
  background-color: #f5f7ff;
  color: #002060;
  justify-content: center;
  transition: 0.3s;
}
.login-button-terms:hover {
  background: #f2f2f2;
}
a {
  text-decoration: none;
  color: #002060;
}
@media screen and (max-width: 640px) {
  .wrapper-terms-text {
    font-size: 0.8em;
    padding: 0;
    height: 200px;
  }
}
</style>
