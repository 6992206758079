<template>
  <div id="app">
    <nav v-if="showFooterAndNav">
      <router-link to="/home">
        <img src="./assets/img/LogoHigh.png" />
      </router-link>
      
      <div class="elements">
        <span class="search" v-if="$router.currentRoute.path != '/home'">
          <router-link to="/home">
            <svg-icon icon="search_2"></svg-icon>
          </router-link>
        </span>
        <span class="profile">



          <!-- <router-link
            :to="
              user && user.agreeOnTerms
                ? '/dashboard'
                : user && !user.agreeOnTerms
                ? '/login/terms'
                : '/login'
            "
          >
            <LoginIcon />
          </router-link> -->
              <router-link
            to="/dashboard"
          >
        <svg-icon icon="account_1"></svg-icon>
          </router-link>
        </span>
        <span class="cart">
          <router-link to="/basket">
             <svg-icon icon="book_1"></svg-icon>
          </router-link>
        </span>
        <span class="basket">
          <router-link to="/basket" class="counter">{{
            $store.state.basket.items.length || 0
          }}</router-link>
        </span>
      </div>
    </nav>
    <router-view class="view" />
    <PreFooter v-if="currentRouteName === '/home'" />
    <Footer v-if="showFooterAndNav" />
    <CookieSettings ref="cookieSettingsRef" />
    <Cookies @showCookieSettings="$refs.cookieSettingsRef.show()" />
    <InstagramAlert v-if="isMobile() && isInstagram()" />
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import './assets/css/_variables';

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background: $background!important;
  /*new color = background: #4285f417!important */
  color: $darkGrey;
  font-family: 'Open Sans', sans-serif;
  overflow-y: scroll;
}

button {
  text-align: center;
  background: $blue;
  color: white;
  padding: 18px 65px;
  border: none;
  border-radius: 33px;
  font-size: 20px;
  cursor: pointer;

  transition: transform 0.3s;

  &:hover {
    background: #097def;
  }

  &:active {
    transform: translateY(5px);
  }

  @media screen and (max-width: 640px) {
    font-size: 1em;
  }
}

.view {
  min-height: 80vh;
}

.counter {
  background: $blue;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 22px;
}

#app {
  width: 100%;

  nav {
    padding: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
      max-width: 100px;

      @media screen and (max-width: 640px) {
        max-width: 90px;
      }
    }

    .elements {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      .basket {
        @media screen and (max-width: 640px) {
          .cart {
            width: 2.3rem;
          }
          .basket {
            font-size: 20px;
          }
        }
      }

      .cart,
      .search,
      .profile {
        margin-right: 1em;
      }
    }

    h2 {
      font-size: 3em;
      margin: 0px;
      cursor: pointer;

      span {
        color: white;
        background: $blue;
        padding: 0px 12px;
      }

      @media screen and (max-width: 640px) {
        font-size: 2em;
      }
    }
  }
}
</style>

<script>
import Footer from './components/Footer.vue';
// import FlatIcon from './components/FlatIcon.vue';
// import LoginIcon from './components/LoginIcon.vue';
import Cookies from './components/Cookies.vue';
import CookieSettings from './components/CookieSettings.vue';
import InstagramAlert from './components/InstagramAlert.vue';
import PreFooter from './components/PreFooter.vue';


export default {
  name: 'App',
  components: {
    Footer,
    // FlatIcon,
    // LoginIcon,
    Cookies,
    CookieSettings,
    InstagramAlert,
    PreFooter,
  },
  data() {
    return {
      loader: null,
    };
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isInstagram() {
      const ua = navigator.userAgent || navigator.vendor || window.opera;
      const isInstagram = ua.indexOf('Instagram') > -1 ? true : false;
      return isInstagram;
    },
  },
  created() {
    this.loader = this.$loading.show({});
  },
  mounted() {
    this.loader.hide();
    console.log(navigator.userAgent);
    //Wipe basket if older than 30min
    if (Date.now() - this.$store.state.basket.lastUpdate > 60 * 30 * 1000) {
      this.$store.commit('updateBasket', []);
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    currentRouteName() {
      return this.$route.path;
    },
    showFooterAndNav() {
      if (
        this.$route.path.includes('login') ||
        this.$route.path.includes('registration-form')
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
