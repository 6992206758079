import axios from 'axios';
import qs from 'qs';
import config from '@/config';

// Define the reduceData function
function reduceData(o) {
  return typeof o === 'string' || Object.keys(o).length === 0
    ? o
    : Array.isArray(o)
    ? o.map((v) => reduceData(v))
    : Object.keys(o).reduce((acc, key) => {
        if (o[key]) {
          acc[key] =
            find.transform(o[key]) ||
            findMany.transform(o[key]) ||
            reduceData(o[key]);
        }

        return acc;
      }, {});
}

const find = {
  transform: (input) => {
    return input.data && input.data.id && input.data.attributes
      ? {
          id: input.data.id,
          ...reduceData(input.data.attributes),
        }
      : undefined;
  },
};

const findOne = {
  transform: (input) => {
    return input.data && input.data[0]
      ? {
          id: input.data[0].id,
          ...reduceData(input.data[0].attributes),
        }
      : undefined;
  },
};

const findMany = {
  transform: (input) => {
    return input.data &&
      Array.isArray(input.data) &&
      typeof input.data !== 'string'
      ? input.data.map((d) => {
          return d.id && d.attributes
            ? {
                id: d.id,
                ...reduceData(d.attributes),
              }
            : d;
        })
      : undefined;
  },
};

const api = axios.create({
  baseURL: `${config.strapiURL}/api`,
  paramsSerializer: function (params) {
    return qs.stringify(params, { encodeValuesOnly: true });
  },
});

const token = config.strapiToken;

api.interceptors.request.use(function (config) {
  const authToken = config.customData?.userToken ?? token;
  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});
// Export the API instance with the find, findOne, and findMany objects attached
export default {
  install(Vue) {
    Vue.prototype.$api = api;
    Vue.prototype.$find = find;
    Vue.prototype.$findOne = findOne;
    Vue.prototype.$findMany = findMany;
  },
  api, // api instance for Vuex import
};
