import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import createPlugin from 'logrocket-vuex';
import LogRocket from 'logrocket';

import customAPI from '@/plugins/axios.js';

const api = customAPI.api;

Vue.use(Vuex);

const logrocketPlugin = createPlugin(LogRocket);

export default new Vuex.Store({
  state: {
    basket: {
      items: [],
      lastUpdate: Date.now(),
    },
    cookies: null,
    apiKey: null,
    downloadCount: 0,
    user: null,
    lastRoute: null,
  },
  mutations: {
    removeFromBasket(state, item) {
      state.basket.items = state.basket.items.filter(
        (i) => i.id !== item.id || i.type !== item.type
      );
      state.basket.lastUpdate = Date.now();
    },
    addToBasket(state, item) {
      state.basket.items.push(item);
      state.basket.lastUpdate = Date.now();
    },
    updateBasket(state, items) {
      state.basket.items = items;
      state.basket.lastUpdate = Date.now();
    },
    acceptCookies(state, item) {
      state.cookies = item;
      if (item.ga) window.ga(true);
      if (item.lr) LogRocket.init('3vyzuv/heyjura');
    },
    setAdminAPIKey(state, key) {
      state.apiKey = key;
    },
    setDownloadCount(state, count) {
      state.downloadCount = count;
    },
    setUser(state, user) {
      state.user = user;
    },
    updateUser(state, updatedUserData) {
      state.user = {
        ...updatedUserData,
        jwt: state.user.jwt,
      };
    },
    setLastRoute: (state, route) => {
      state.lastRoute = route;
    },
  },
  actions: {
    saveUserData({ commit }, userData) {
      commit('setUser', userData);
    },
    async fetchAndUpdateUserData({ commit, state }) {
      if (!state.user || !state.user.jwt) {
        console.error('User not logged in or JWT missing');
        return;
      }

      try {
        const response = await api.get('/users/me', {
          customData: {
            userToken: state.user.jwt,
          },
        });

        if (response.status === 200) {
          commit('updateUser', response.data);
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    logout({ commit }) {
      commit('setUser', null);
    },
  },
  getters: {
    getUser: (state) => state.user,
    isAuthenticated: (state) => !!state.user,
  },
  plugins: [
    new VuexPersistence({ storage: window.localStorage }).plugin,
    logrocketPlugin,
  ],
});
