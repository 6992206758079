<template>
  <div class="spinner">
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style scoped>
.spinner {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 60px auto;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #4285f4;
  border-radius: 100%;
  animation: bouncedelay 1.4s infinite ease-in-out;
  animation-fill-mode: both;
}

.dot::before,
.dot::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #4285f4;
  border-radius: 100%;
  animation: bouncedelay 1.4s infinite ease-in-out;
  animation-fill-mode: both;
}

.dot::before {
  left: -20px;
  animation-delay: -0.32s;
}

.dot::after {
  left: 20px;
  animation-delay: -0.16s;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    opacity: 0.1;
  }
  40% {
    opacity: 1;
  }
}
</style>
