<template>
 <svg-icon icon="book_2" class="icon" @click.native="addToBasket()"></svg-icon>
  <!-- <FlatIcon icon="basket" class="icon" @click.native="addToBasket()" /> -->
</template>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
}
</style>

<script>
// import FlatIcon from './FlatIcon.vue';

export default {
  name: 'AddToBasketBtn',
  props: ['item'],
  components: {
    // FlatIcon,
  },
  methods: {
    addToBasket() {
      const itemID = this.item.id;
      if (this.$store.state.basket.items.find((i) => i.id == itemID)) {
        this.$toast.open({
          message: `"${this.item.title}" ist bereits im Warenkorb`,
          type: 'warning',
        });
        return;
      }
      this.$store.commit('addToBasket', this.item);
      this.$toast.open(`"${this.item.title}" wurde zum Warenkorb hinzugefügt`);
    },
  },
};
</script>
