<template>
  <div class="wrapper-terms">
    <div class="box">
      <div class="wrapper-terms-text">
        <h1 class="terms-tittle">Einverstanden</h1>
        <p class="terms-tittle-2">
          Unsere Datenschutzerklärung findest Du
          <a target="_blank" href="https://heyjura.de/privacy"
            ><strong> hier. </strong>
          </a>
        </p>
        <p class="terms-tittle-2">
          Durch Drücken des “Weiter-Buttons” erklärst Du Dich mit unseren
          <a href="/agb_heyJura.pdf" target="_blank">
            <strong> AGB’s </strong>
          </a>
          einverstanden.
        </p>
        <div class="newsletter_funnel">
          <input type="checkbox" v-model="subscribeToNewsletter" id="newsletterCheckbox" />
          <label for="newsletterCheckbox">Klicke hier, wenn Du <b>keine</b> Lerntipps, Studyhacks oder Marketinginfos erhalten möchtest.</label>
        </div>
      </div>
      <div class="wrapper-terms">
        <button
          class="login-button-terms"
          type="button"
          @click="agreeOnTerms()"
        >
          <span>Weiter</span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
box {
  padding: 8em 5em;
  background-color: #ffffff;
  border-radius: 8px;
  
  @media screen and (max-width: 640px) {
    padding: 5em 2em;
  }
  
  @media screen and (max-width: 480px) {
    padding: 5em 0em;
  }
}
  
.wrapper-terms {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 90%;
  height: 50%;
  border-radius: 8px;
  margin: 5%;
  padding: 0;
}
  
.wrapper-terms-text {
  display: flex;
  flex-direction: column;
  width: 362px;
  height: auto;
  border-radius: 8px;
  margin: auto;
}
  
.terms-tittle {
  text-align: center;
  color: #002060;
  font-size: 2.5em;
}
  
.terms-tittle-2, .newsletter_funnel {
  text-align: center;
  color: #002060;
}

.newsletter_funnel {
  margin: 1em 0em;
  }
  
.login-button-terms {
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 250px;
  height: 60px;
  font-size: 1em;
  background-color: #f5f7ff;
  border-radius: 33px;
  color: #002060;
  justify-content: center;
  transition: 0.3s;
}
  
.login-button-terms:hover {
  background: #f2f2f2;
}
  
a {
  text-decoration: none;
  color: #002060;
}
  
@media screen and (max-width: 640px) {
  .wrapper-terms-text {
    font-size: 0.8em;
    padding: 0;
    height: auto;
  }
}
</style>

<script>
export default {
  name: 'Terms',
  components: {},
  methods: {
    async agreeOnTerms() {
      try {
        const response = await this.$api.put(
          '/user/me',
          {
            agreeOnTerms: true, // Change this to true since user is agreeing to the terms
          },
          {
            customData: {
              userToken: this.$store.state.user?.jwt ?? '',
            },
          }
        );

        if (response.status === 200) {
          // Update the user data in the Vuex store
          await this.$store.dispatch('fetchAndUpdateUserData');

          // Wait for the component to update and navigate to the dashboard
          this.$nextTick(() => {
            if (this.$store.state.user.tokens || !this.$store.state.user.registrationFormCompleted) {
              this.$router.push('/dashboard');
            } else {
              this.$router.push('/bundles');
            }
          });
        } else {
          throw new Error('Failed to agree on terms');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
  },
  mounted() {
    if (this.$store.state.user && this.$store.state.user.agreeOnTerms) {
      this.$router.push('/dashboard');
    }
  },
};
</script>
